import { useState } from "react";
import PlaceOrder from "../Assets/PlaceOrder.mp4"
import venmo from "../Assets/venmo-logo.svg"
import Cash_App from "../Assets/Cash_App-logo.png"
import MetaMask from "../Assets/MetaMask-logo.png"
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import "../styles/Buy.css"

const Hero = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    return (
        <div className="pb-20 pt-35 md:pt-10 xl:pb-25 xl:pt-46 w-[100%] bg-[#181C30]">
            <div className="px-4 md:px-8">
                <div className="container mb-10">
                    <h1 className="neon mb-10">DEPOSIT $5, GET $200 BONUS:#F4WINNER</h1>
                </div>
                <div className="flex flex-wrap max-md:w-[90%] xl:w-[80%] mx-auto xl:justify-between ">
                    <div className="w-[95%] sm:w-[80%] mx-auto xl:w-[45%] max-md:mt-10 bg-[#18181B]">
                        {/* First DropDown */}
                        <div className="w-[90%] mx-auto ">
                            <div className="my-6 shadow-medium hover:shadow-lg">
                                <div
                                    className="bg-[#18181B] text-[#ECEDEE] text-normal max-md:text-sm font-normal py-3 px-4 rounded-t cursor-pointer flex justify-between"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    <img src={venmo} alt="venmo" className="w-20" />
                                    {/* <p className="text-[#999AA1] text-lg font-medium">Venmo</p> */}
                                    {isOpen ? <TiArrowSortedUp size="24px" /> : <TiArrowSortedDown size="24px" />}
                                </div>

                                {isOpen && (
                                    <div className="bg-[#18181B] rounded-b py-4 px-4">
                                        <div className=" mx-auto">
                                            <p className="text-sm text-[#999AA1] mb-4">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis atque alias corrupti earum harum excepturi eum mollitia id quas inventore quia magni quaerat, ipsa maxime fugiat quam ex repellat non?
                                            </p>

                                        </div>
                                    </div>
                                )}

                                <style jsx>{`
        div {
          transition: all 0.3s ease;
        }
      `}</style>
                            </div>
                            <hr className="opacity-30" />
                        </div>
                        {/* Second DropDown */}
                        <div className="w-[90%] mx-auto ">
                            <div className="my-6 shadow-medium hover:shadow-lg">
                                <div
                                    className="bg-[#18181B] text-[#ECEDEE] text-normal max-md:text-sm font-normal py-3 px-4 rounded-t cursor-pointer flex justify-between"
                                    onClick={() => setIsOpen2(!isOpen2)}
                                >
                                    <img src={Cash_App} alt="Cash_App" className="w-12" />
                                    {/* <p className="text-lg font-medium text-[#999AA1]">CashApp</p> */}
                                    {isOpen2 ? <TiArrowSortedUp size="24px" /> : <TiArrowSortedDown size="24px" />}
                                </div>

                                {isOpen2 && (
                                    <div className="bg-[#18181B] text-[#ECEDEE]   rounded-b py-4 px-4">
                                        <div className="mx-auto">
                                            <p className="text-sm text-[#999AA1] mb-4">
                                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptates porro perferendis unde optio odio atque enim nisi nesciunt id sint perspiciatis corporis, dignissimos molestias et architecto distinctio ab esse! Ipsa.
                                            </p>

                                        </div>
                                    </div>
                                )}

                                <style jsx>{`
        div {
          transition: all 0.3s ease;
        }
      `}</style>
                            </div>
                            <hr className="opacity-30" />
                        </div>
                        {/* Third DropDown */}
                        <div className="w-[90%] mx-auto ">
                            <div className="my-6 shadow-medium hover:shadow-lg">
                                <div
                                    className="bg-[#18181B] text-[#ECEDEE] text-normal max-md:text-sm font-normal py-3 px-4 rounded-t cursor-pointer flex justify-between"
                                    onClick={() => setIsOpen3(!isOpen3)}
                                >
                                    <img src={MetaMask} alt="MetaMask" className="w-28" />
                                    {/* <p className="text-lg font-medium text-[#999AA1]"> Crypto</p> */}
                                    {isOpen3 ? <TiArrowSortedUp size="24px" /> : <TiArrowSortedDown size="24px" />}
                                </div>

                                {isOpen3 && (
                                    <div className="bg-[#18181B] rounded-b py-4 px-4">
                                        <div className=" mx-auto">
                                            <p className="text-sm text-[#999AA1] mb-4">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et, exercitationem iusto praesentium quasi obcaecati tempore odio reprehenderit, cum nihil temporibus, tempora incidunt dolorem reiciendis eaque commodi. Vel, sunt delectus.
                                            </p>

                                        </div>
                                    </div>
                                )}

                                <style jsx>{`
        div {
          transition: all 0.3s ease;
        }
      `}</style>
                            </div>
                        </div>
                    </div>

                    <div className="w-[95%] sm:w-[80%] mx-auto max-xl:mt-10 xl:w-[35%] md:flex md:items-center md:justify-center">
                        <video autoPlay loop muted className=" xl:w-[300px] ">
                            <source src={PlaceOrder} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
