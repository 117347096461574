import React, { useEffect } from "react";
import { motion } from 'framer-motion';
import results1 from "../Assets/results1.png"
import results2 from "../Assets/results2.png"
import results3 from "../Assets/results3.png"
import results4 from "../Assets/results4.png"
import results5 from "../Assets/results5.png"
import "../styles/Marquee.css"
import Reeller from "reeller";
import gsap from 'gsap';
// Define the array of slides with numbers
// const slides = [
//     { number: "https://res.cloudinary.com/tpostr/image/upload/v1553865338/paparouna/IMG_7638-01.jpg" },
//     { number: 2 },
//     { number: 3 },
//     { number: 4 },
//     { number: 5 },
// ];

const slides = [
    {
        src: "https://cdn.cuberto.com/cb/hello/overview/1.mp4",
        alt: "Result 1",
        caption: "Results"
    },
    {
        src: "https://cdn.cuberto.com/cb/hello/overview/3.mp4",
        alt: "Result 2",
        caption: "Results"
    },
    {
        src: "https://cdn.cuberto.com/cb/hello/overview/1.mp4",
        alt: "Result 2",
        caption: "Results"
    },
    {
        src: "https://cdn.cuberto.com/cb/hello/overview/3.mp4",
        alt: "Result 4",
        caption: "Results"
    },
    {
        src: "https://cdn.cuberto.com/cb/hello/overview/1.mp4",
        alt: "Result 5",
        caption: "Results"
    },
    {
        src: "https://cdn.cuberto.com/cb/hello/overview/3.mp4",
        alt: "Result 5",
        caption: "Results"
    }
];
export default function Marquee() {
    const duplicatedSlides = [...slides, ...slides];


    Reeller.registerGSAP(gsap);
    useEffect(() => {
        // Assuming Reeller is globally available, or you have imported it correctly
        const reeller = new Reeller({
            container: ".sw-partner-marquee",
            wrapper: ".sw-partner-marquee-row",
            itemSelector: ".sw-partner-marquee-row-item",
            speed: 40,
        });
    }, []);
    return (
        // <div className="relative w-full overflow-hidden">
        //     {/* Wrapping div for seamless looping */}
        //     <motion.div
        //         className="flex"
        //         animate={{
        //             x: ['-100%', '0%'],
        //             transition: {
        //                 ease: 'linear',
        //                 duration: 50,
        //                 repeat: Infinity,
        //             }
        //         }}
        //     >
        //         {/* Render duplicated slides */}
        //         {duplicatedSlides.map((slide, index) => (
        //             <div key={index} className="flex-shrink-0" style={{ width: `${100 / slides.length}%` }}>
        //                 <div className="flex flex-col items-center justify-center h-full text-6xl">
        //                     {/* <img src={slide.src} alt={slide.alt} className="w-60" /> */}
        //                     {/* <div className="display-video">
        //                         <video
        //                             src={slide.src}
        //                             autoplay=""
        //                             playsinline=""
        //                             loop=""
        //                             muted=""
        //                             poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
        //                         ></video>
        //                     </div> */}
        //                     <div className="sw-partner-logo">
        //                         <div className="display-expla">
        //                             <span>
        //                                 DESIGN <br />
        //                                 STUDIO
        //                             </span>
        //                         </div>
        //                         <div className="display-video">
        //                             <video
        //                                 src="https://cdn.cuberto.com/cb/hello/overview/1.mp4"
        //                                 autoplay=""
        //                                 playsinline=""
        //                                 loop=""
        //                                 muted=""
        //                                 poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
        //                             ></video>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         ))}
        //     </motion.div>
        // </div>


        <div className="sw-partner-marquee">
            <div className="sw-partner-marquee-row">
                <div className="sw-partner-marquee-row-item">
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/1.mp4"
                                autoplay=""
                                playsinline=""
                                loop=""
                                muted=""
                                poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/3.webm"
                                autoplay=""
                                poster="https://i.postimg.cc/nhKnKrKW/videoframe-6866.png"
                                playsinline=""
                                loop=""
                                muted=""
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/1.mp4"
                                autoplay=""
                                playsinline=""
                                loop=""
                                muted=""
                                poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/3.webm"
                                autoplay=""
                                poster="https://i.postimg.cc/nhKnKrKW/videoframe-6866.png"
                                playsinline=""
                                loop=""
                                muted=""
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/1.mp4"
                                autoplay=""
                                playsinline=""
                                loop=""
                                muted=""
                                poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/3.webm"
                                autoplay=""
                                poster="https://i.postimg.cc/nhKnKrKW/videoframe-6866.png"
                                playsinline=""
                                loop=""
                                muted=""
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/1.mp4"
                                autoplay=""
                                playsinline=""
                                loop=""
                                muted=""
                                poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/3.webm"
                                autoplay=""
                                poster="https://i.postimg.cc/nhKnKrKW/videoframe-6866.png"
                                playsinline=""
                                loop=""
                                muted=""
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/1.mp4"
                                autoplay=""
                                playsinline=""
                                loop=""
                                muted=""
                                poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/3.webm"
                                autoplay=""
                                poster="https://i.postimg.cc/nhKnKrKW/videoframe-6866.png"
                                playsinline=""
                                loop=""
                                muted=""
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/1.mp4"
                                autoplay=""
                                playsinline=""
                                loop=""
                                muted=""
                                poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/3.webm"
                                autoplay=""
                                poster="https://i.postimg.cc/nhKnKrKW/videoframe-6866.png"
                                playsinline=""
                                loop=""
                                muted=""
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/1.mp4"
                                autoplay=""
                                playsinline=""
                                loop=""
                                muted=""
                                poster="https://i.postimg.cc/Z5zTypN3/videoframe-2436.png"
                            ></video>
                        </div>
                    </div>
                    <div className="sw-partner-logo">
                        <div className="display-expla">
                            <span>
                                EARN <br />
                                TODAY
                            </span>
                        </div>
                        <div className="display-video">
                            <video
                                src="https://cdn.cuberto.com/cb/hello/overview/3.webm"
                                autoplay=""
                                poster="https://i.postimg.cc/nhKnKrKW/videoframe-6866.png"
                                playsinline=""
                                loop=""
                                muted=""
                            ></video>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
