import Results from "../Assets/Results.mp4"
import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import results1 from "../Assets/results1.png"
import results2 from "../Assets/results2.png"
import results3 from "../Assets/results3.png"
import results4 from "../Assets/results4.png"
import results5 from "../Assets/results5.png"

// Define the array of slides with numbers
// const slides = [
//     { number: "https://res.cloudinary.com/tpostr/image/upload/v1553865338/paparouna/IMG_7638-01.jpg" },
//     { number: 2 },
//     { number: 3 },
//     { number: 4 },
//     { number: 5 },
// ];

const slides = [
    {
        src: results1,
        alt: "Result 1",
        caption: "Results"
    },
    {
        src: results2,
        alt: "Result 2",
        caption: "Results"
    },
    {
        src: results3,
        alt: "Result 2",
        caption: "Results"
    },
    {
        src: results4,
        alt: "Result 4",
        caption: "Results"
    },
    {
        src: results5,
        alt: "Result 5",
        caption: "Results"
    }
];




export default function TrueResults() {
    const duplicatedSlides = [...slides, ...slides];
    return (
        <div className="bg-[#181C30] text-white py-20">
            <h3 className="text-3xl text-center font-semibold">This is what we are
                made of 💪🤑</h3>

            <div className="my-20 w-[95%] sm:w-[80%] mx-auto max-xl:mt-10 xl:w-[45%] flex items-center justify-center ">
                <video autoPlay loop muted className="max-sm:w-[260px] max-sm:h-[180px] sm:w-[460px] sm:h-[215px] md:w-[560px] md:h-[315px] object-cover shadow-sm shadow-white">
                    <source src={Results} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <h3 className="text-3xl text-center font-semibold my-20">400+ HUGE wins, 4
                years experience,
                play with the pros</h3>


            {/* Slider */}
            <div className="relative w-full overflow-hidden">
                {/* Wrapping div for seamless looping */}
                <motion.div
                    className="flex"
                    animate={{
                        x: ['-100%', '0%'],
                        transition: {
                            ease: 'linear',
                            duration: 50,
                            repeat: Infinity,
                        }
                    }}
                >
                    {/* Render duplicated slides */}
                    {duplicatedSlides.map((slide, index) => (
                        <div key={index} className="flex-shrink-0" style={{ width: `${100 / slides.length}%` }}>
                            <div className="flex flex-col items-center justify-center h-full text-6xl">
                                <img src={slide.src} alt={slide.alt} className="w-60" />
                            </div>
                        </div>
                    ))}
                </motion.div>
            </div>


        </div>
    )
}
