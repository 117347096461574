import { useState } from "react";
import { useNavigate } from "react-router-dom";
import WinBigNow from "../Assets/WinBigNow.mp4"
import Marquee from "./Marquee";


const Hero = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/share");
    };

    return (
        <div className="pb-20 pt-35 md:pt-10 xl:pb-25 xl:pt-46 w-[100%] bg-[#181C30]">
            <div className="px-4 md:px-8">
                <div className="flex flex-wrap max-md:w-[90%] xl:w-[80%] mx-auto xl:justify-between ">
                    <div className="w-[95%] sm:w-[80%] mx-auto xl:w-[45%] pt-10">
                        <span className="badge mb-2 inline-block text-sm bg-green-50 hover:bg-green-100 text-green-900 border border-green-100 py-1 px-3 rounded-md transition-all mb-4.5 text-md font-medium ">
                            🔥 $200 starting bonus. Bet insurance for only $9.99 p/m
                        </span>
                        <h3 className="mb-5 xl:pr-16 text-lg md:text-3xl font-semibold text-white xl:text-hero ">
                            $4.99 to start an account with a 1-on-1
                            analyst and pro sports trader. Play
                            with the pros
                        </h3>

                        <p className="text-[#999AA1]">
                            Tired of being broke? Want to actually earn life
                            changing money and move up in the world? We don't
                            play for fun, we play to win. Teams of expert
                            analysis with years of experience, and wins across
                            the US, here to make you win big, and change your
                            life. Be who you've always wanted to be. Play with
                            the pros.
                        </p>


                        <div className="mt-10">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-wrap  md:gap-5">
                                    <button
                                        aria-label="get started button"
                                        className="flex rounded-full px-8 py-2.5 text-gray-800 duration-300 ease-in-out bg-[#F9C80E] hover:bg-[#c0a02a]"
                                    >
                                        Get $200 now
                                    </button>
                                </div>
                            </form>


                            <p className="mt-5 text-white">
                                No credit card required.
                            </p>
                        </div>
                    </div>

                    <div className="w-[95%] sm:w-[80%] mx-auto max-xl:mt-10 xl:w-[45%] flex items-center justify-center">
                        <video autoPlay loop muted className="max-sm:w-[260px] max-sm:h-[180px] sm:w-[460px] sm:h-[215px] md:w-[560px] md:h-[370px] object-cover ">
                            <source src={WinBigNow} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
            <Marquee />
        </div>
    );
};

export default Hero;
