// import { useTheme } from "next-themes";
import fortune4_Logo from "../Assets/fortune4_Logo.png"

const ThemeToggler = () => {
    // const { theme, setTheme } = useTheme();

    return (
        <button
            aria-label="theme toggler"
            className="bg-gray-2 dark:bg-dark-bg absolute right-17 mr-1.5 flex cursor-pointer items-center justify-center rounded-full text-black dark:text-white lg:static"
        >
            <img
                src={fortune4_Logo}
                alt="logo"
                width={21}
                height={21}
            />
        </button>
    );
};

export default ThemeToggler;
