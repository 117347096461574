
// import React from "react";
// import fortune4_Logo from "../Assets/fortune4_Logo.png"
// import { Link } from "react-router-dom";

// export default function Header() {
//     return (
//         <nav className="bg-[#181C30]">
//             <div className="w-[95%] sm:w-[75%] flex flex-wrap items-center justify-between mx-auto p-4">
//                 <Link to={"/"} className="flex items-center space-x-3 rtl:space-x-reverse">
//                     <div className="my-8 flex items-center">
//                         <img
//                             src={fortune4_Logo}
//                             alt="fortune4_Logo"
//                             className="w-[30px] md:w-[60px] h-[auto]"
//                         />
//                         <h2 className="text-lg sm:text-2xl md:text-3xl font-normal text-[#e5e7eb]">FORTUNES4</h2>
//                     </div>
//                 </Link>

//                 <div className=" block w-auto" id="navbar-default">
//                     <ul className="font-medium flex p-4 md:p-0 border rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
//                         <li>
//                             <Link to={"/"} className="px-3 rounded md:p-0 text-[#999AA1] hover:text-gray-100">Home</Link>
//                         </li>
//                         <li>
//                             <Link to={"/share"} className="px-3 rounded md:p-0 text-[#999AA1] hover:text-gray-100">Share</Link>
//                         </li>
//                         <li>
//                             <Link to={"/buy"} className="px-3 rounded md:p-0 text-[#999AA1] hover:text-gray-100">Buy</Link>
//                         </li>
//                         <li>
//                             <Link to={"/true-results"} className="px-3 rounded md:p-0 text-[#999AA1] hover:text-gray-100">True Results</Link>
//                         </li>
//                     </ul>
//                 </div>
//             </div>
//         </nav>

//     )
// }



import { Link } from "react-router-dom";
// import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import fortune4_Logo from "../Assets/fortune4_Logo.png"
import { FaDiscord } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import ThemeToggler from "./ThemeToggler.js";
import { menuData } from "./menuData";

const Header = () => {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const [dropdownToggler, setDropdownToggler] = useState(false);
    const [stickyMenu, setStickyMenu] = useState(false);

    // const pathUrl = usePathname();
    let pathUrl = "https://mubbasheryasin.com"
    // Sticky menu
    const handleStickyMenu = () => {
        if (window.scrollY >= 80) {
            setStickyMenu(true);
        } else {
            setStickyMenu(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleStickyMenu);
    });

    return (
        <div className="bg-[#181C30] text-white">
            <div
                className={`relative left-0 top-0 z-99999 w-[99%] sm:w-[78%] mx-auto py-7 ${stickyMenu
                    ? "!py-4 shadow transition duration-100"
                    : ""
                    }`}
            >
                <div className="relative mx-auto max-w-c-1390 items-center justify-between px-4 md:px-8 xl:flex 2xl:px-0">
                    <div className="flex w-full items-center justify-between xl:w-1/4">
                        <Link to={"/"} className="flex items-center xl:items-end">
                            <img
                                src={fortune4_Logo}
                                alt="logo"
                                className="h-10 md:h-12"
                            />
                            <h3 className=" text-xl fortunes4-logo">FORTUNES4</h3>
                        </Link>

                        {/* <!-- Hamburger Toggle BTN --> */}
                        <button
                            aria-label="hamburger Toggler"
                            className="block xl:hidden"
                            onClick={() => setNavigationOpen(!navigationOpen)}
                        >
                            {/* <span className="relative block h-5.5 w-5.5 cursor-pointer">
                                <span className="absolute right-0 block h-full w-full">
                                    <span
                                        className={`relative left-0 top-0 my-1 block h-0.5 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${!navigationOpen ? "!w-full delay-300" : "w-0"
                                            }`}
                                    ></span>
                                    <span
                                        className={`relative left-0 top-0 my-1 block h-0.5 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${!navigationOpen ? "delay-400 !w-full" : "w-0"
                                            }`}
                                    ></span>
                                    <span
                                        className={`relative left-0 top-0 my-1 block h-0.5 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${!navigationOpen ? "!w-full delay-500" : "w-0"
                                            }`}
                                    ></span>
                                </span>
                                <span className="du-block absolute right-0 h-full w-full rotate-45">
                                    <span
                                        className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${!navigationOpen ? "!h-0 delay-[0]" : "h-full"
                                            }`}
                                    ></span>
                                    <span
                                        className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${!navigationOpen ? "!h-0 delay-200" : "h-0.5"
                                            }`}
                                    ></span>
                                </span>
                            </span> */}
                            <RxHamburgerMenu />
                        </button>
                        {/* <!-- Hamburger Toggle BTN --> */}
                    </div>

                    {/* Nav Menu Start   */}
                    <div
                        className={`invisible w-[96%] mx-auto h-0 items-center justify-between xl:visible xl:flex xl:h-auto xl:w-full ${navigationOpen &&
                            "navbar !visible mt-4 h-auto max-h-[400px] rounded-md bg-[#181C30]  p-7.5 shadow-solid-5 dark:bg-blacksection xl:h-auto xl:p-0 xl:shadow-none xl:dark:bg-transparent"
                            }`}
                    >
                        <nav>
                            <ul className="mt-6 flex flex-col gap-5 xl:flex-row xl:items-center xl:gap-10">
                                {menuData.map((menuItem, key) => (
                                    <li key={key} className={menuItem.submenu && "group relative"}>
                                        {menuItem.submenu ? (
                                            <>
                                                <button
                                                    onClick={() => setDropdownToggler(!dropdownToggler)}
                                                    className="flex cursor-pointer items-center justify-between gap-3 hover:text-primary"
                                                >
                                                    {menuItem.title}
                                                    <span>
                                                        <svg
                                                            className="h-3 w-3 cursor-pointer fill-waterloo group-hover:fill-primary"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                        >
                                                            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                                                        </svg>
                                                    </span>
                                                </button>

                                                <ul
                                                    className={`dropdown ${dropdownToggler ? "flex" : ""}`}
                                                >
                                                    {menuItem.submenu.map((item, key) => (
                                                        <li key={key} className="hover:text-primary">
                                                            <Link href={item.path || "#"}>{item.title}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (
                                            <Link
                                                to={`${menuItem.path}`}
                                                className={
                                                    pathUrl === menuItem.path
                                                        ? "text-primary hover:text-primary"
                                                        : "hover:text-primary"
                                                }
                                            >
                                                {menuItem.title}
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <div className="mt-7 flex flex-wrap items-center pb-3 xl:items-end gap-6 xl:mt-0">
                            {/* <ThemeToggler /> */}

                            <Link
                                to={"/share"}
                                className="flex items-center xl:items-end justify-center rounded-full bg-primary px-7.5 py-2.5 text-regular text-white duration-300 ease-in-out hover:bg-primaryho xl:-mb-4"
                            >
                                <button
                                    aria-label="get started button"
                                    className="flex items-center rounded-full px-8 py-2.5 text-gray-800 duration-300 ease-in-out bg-[#F9C80E] hover:bg-[#c0a02a]"
                                >
                                    <FaDiscord className="mr-2" />
                                    Discord
                                </button>
                            </Link>
                            <a
                                href="tel:+1(434)2318581"
                                className="flex items-center xl:items-end justify-center rounded-full bg-primary px-7.5 py-2.5 text-regular text-white duration-300 ease-in-out hover:bg-primaryho xl:-mb-4"
                            >
                                <button
                                    aria-label="chatbubble-oval-left-ellipsis"
                                    title="chatbubble-oval-left-ellipsis"
                                    className="flex items-center rounded-full px-8 py-2.5 text-gray-800 duration-300 ease-in-out bg-[#F9C80E] hover:bg-[#c0a02a]"

                                >
                                    <FaPhoneAlt className="mr-2" />
                                    +1 (434) 231-8581
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// w-full delay-300

export default Header;
