import React, { useState, useEffect, useRef } from 'react';
import "../styles/Share.css"
// import Confetti from "react-confetti";
// import useWindowSize from "react-use/lib/useWindowSize";
import ConfettiExplosion from 'react-confetti-explosion';
import SubscribeImg from "../Assets/SubscribeImg.jpg"
import { FaInstagram } from "react-icons/fa6";
import { BiLogoFacebookSquare } from "react-icons/bi";


const SpinnerAnimation = () => {
    const [started, setStarted] = useState(false);
    const [stopped, setStopped] = useState(false);
    const [stopAnimation, setStopAnimation] = useState(false);
    const [lowerSpeed, setLowerSpeed] = useState(0);
    const [ticks, setTicks] = useState(0);
    const [offSet, setOffSet] = useState(0);
    const [recycle, setRecycle] = useState(false);
    const [tick, setTick] = useState(false);
    const [speed, setSpeed] = useState(0);
    const [winningItem, setWinningItem] = useState(null);
    const [firstRound, setFirstRound] = useState(true);
    const [winningText, setWinningText] = useState('');
    const [isSpinning, setIsSpinning] = useState(false);
    const [winner, setWinner] = useState(null);

    const [showConfetti, setShowConfetti] = useState(false);

    const tickSound = useRef(new Audio("https://freesound.org/data/previews/269/269026_5094889-lq.mp3"));
    const winSound = useRef(new Audio("https://freesound.org/data/previews/511/511484_6890478-lq.mp3"));
    const spinnerListRef = useRef(null);

    const reset = () => {
        setStarted(false);
        setStopped(false);
        setStopAnimation(false);
        setLowerSpeed(0);
        setTicks(0);
        setOffSet(0);
        setRecycle(false);
        setTick(false);
        setSpeed(0);
        setWinningItem(0);
        setFirstRound(false);
        setWinningText('');
    };

    // useEffect(() => {
    //     tickSound.current.playbackRate = 4;
    // }, []);

    const start = (speed = 1200) => {
        if (started) return;
        setStarted(true);
        setSpeed(speed);
        loop();
    };

    const loop = () => {
        let dt = 0;
        let last = 0;

        const animationLoop = (ms) => {
            if (recycle) {
                setRecycle(false);
                const item = spinnerListRef.current.firstElementChild;
                spinnerListRef.current.append(item);
            }

            if (tick) {
                setTick(false);
                tickSound.current.play();
            }

            setOffSet((prevOffSet) => {
                const newOffSet = prevOffSet + speed * dt;
                return newOffSet;
            });

            const ct = ms / 1000;
            dt = ct - last;
            last = ct;

            if (offSet >= 122) {
                setRecycle(true);
                setOffSet(0);
                setTick(true);
                setTicks((prevTicks) => prevTicks + 1);
                if (ticks >= 20 && Math.random() * 10 >= 5) {
                    stop();
                }
            }

            if (!stopAnimation) {
                requestAnimationFrame(animationLoop);
            }
        };

        requestAnimationFrame(animationLoop);
    };

    const stop = () => {
        setStopped(true);
        setLowerSpeed(Math.ceil(Math.random() * 10) + 1);
        // Additional logic for handling stop conditions goes here.
    };

    const startSpinning = () => {
        setIsSpinning(true);
        // tickSound.current.play();
        setTimeout(() => {
            const randomWin = Math.floor(Math.random() * 9); // Assuming 9 items
            setWinningItem(randomWin);
            setIsSpinning(false);
            // winSound.current.play();
        }, 3000);

        setTimeout(() => {
            const winningEmoji = "🎉"; // Simplification for example
            setIsSpinning(false);
            setWinner(winningEmoji);
        }, 2000); // Assume the spinning effect lasts for 2 seconds

    };


    useEffect(() => {
        if (winningItem !== null) {
            // Show the confetti when a winningItem is selected
            setShowConfetti(true);
            // Optional: Hide the confetti after a set time
            const timer = setTimeout(() => setShowConfetti(false), 2200); // Match the duration of the confetti animation
            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, [winningItem]); // This effect depends on winningItem



    return (
        <div className='py-20 bg-[#181C30] text-white mx-auto '>

            <div className="flex items-center justify-center mb-20">
                <div className="p-2 mx-6 rounded-2xl shadow-md shadow-slate-200">
                    <div className="flex flex-col md:flex-row rounded-l-xl">
                        <img src={SubscribeImg} className="h-80 object-fit rounded-xl md:h-64 md:rounded-l-xl md:rounded-r-none transform hover:scale-105 hover:rounded-xl duration-200" alt="SubscribeImg" />
                        <div className="p-6 md:p-12">
                            <h1 className="font-serif text-xl font-medium text-center text-white md:text-left">Win prizes with your friends for free!</h1>
                            <p className="max-w-xs my-4 text-xs.leading-5.tracking-wide text-center text-white md:text-left">Spin and win in our social game for free,
                                no catch. Daily winners of prizes up to
                                $100,000</p>

                            <div className="flex flex-col mt-5 space-y-4 md:space-x-3 md:flex-row md:space-y-0">
                                <input type="text" placeholder="Enter your email address" className="p-2 px-4 text-center text-white bg-zinc-800 border border-zinc-600 placeholder:text-xs placeholder:text-center placeholder:md:text-left md:text-left focus:outline-none" />

                                <button className="px-5 py-3 text-xs rounded-md text-zinc-800 bg-lime-500 hover:bg-lime-700 hover:text-white duration-500">Subscribe Now</button>
                            </div>
                            {/* <p className='mt-4'>Follow us on <a href="https://www.instagram.com"><b>Instagram</b></a> and <a href="https://www.facebook.com"><b>Facebook</b></a></p> */}
                            <div className='mt-4 flex'><FaInstagram size={30} className='ml-8 cursor-pointer ' /> <BiLogoFacebookSquare size={30} className='ml-8 cursor-pointer' /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="spinnerContainer" className="container ">
                <ul className={`spinner-items !my-10`} id="spinnerList">
                    {['🐶', '🐷', '🐸', '🐹', '🐵', '🐰', '🐭', '🐮', '🐨'].map((emoji, index) => (
                        <li key={index} className={`spinner-items__item shadow-slate-50 shadow-md ${winningItem === index ? 'win' : ''}`} id={index + 1}>
                            {emoji}
                        </li>
                    ))}
                </ul>

                {winningItem !== null && `Winner: ${['🐶', '🐷', '🐸', '🐹', '🐵', '🐰', '🐭', '🐮', '🐨'][winningItem]}`}
                <div className="spinner__won" id="spinnerWon"></div>


                {showConfetti && <ConfettiExplosion particleCount={600} particleSize={20} duration={2200} />}


                <div className="spinner__won" id="spinnerWon">{winningText}</div>
            </div>
            {/* <div className="button" id="startSpinner" onClick={() => startSpinning()}>Spin Emoji!</div> */}

            <div
                aria-label="get started button"
                id="startSpinner"
                className="mt-8 button w-40 mx-auto text-center flex items-center justify-center rounded-full py-2.5 text-gray-800 duration-300 ease-in-out !bg-[#F9C80E] !hover:bg-[#997e1d] cursor-pointer text-xl" onClick={startSpinning}
            >
                Spin
            </div>

            {/* Canvas */}

        </div>
    );
};

export default SpinnerAnimation;
