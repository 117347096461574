
export const menuData = [
    {
        id: 1,
        title: "Share",
        newTab: false,
        path: "/share",
    },
    {
        id: 2,
        title: "Buy",
        newTab: false,
        path: "/buy",
    },
    {
        id: 3,
        title: "True Results",
        newTab: false,
        path: "/true-results",
    },
];

