import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hero from './Components/Hero';
import Header from './Components/Header';
import Share from "./Components/Share"
import Buy from "./Components/Buy"
import TrueResults from "./Components/TrueResults"
import Footer from "./Components/Footer";


function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route index element={<Hero />} />
        <Route path="/share" element={<Share />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/true-results" element={<TrueResults />} />
      </Routes>
      <Footer />
    </BrowserRouter>

  );
}

export default App;
